.button--simple {
  padding: 10px 10px 12px 10px;
  &.button--no-padding {
    padding: 0;
  }
  .button__inner:after {
    left: 0;
    width: 100%;
  }
  &:active, &:focus {
    box-shadow: none;
  }

  @each $colourName, $colour in $buttonColours {
    &.button--#{"" + $colourName} {
      color: $colour;
      .button__inner:after {
        background: $colour;
      }
      svg {
        fill: $colour;
      }
      &:hover {
        color: darken($colour, 10%);
        .button__inner:after {
          background: darken($colour, 10%);
        }
        svg {
          fill: darken($colour, 10%);
        }
      }
    }
  }
  &[disabled], &:hover[disabled] {
    opacity: 1;
    color: $grey-mid;
    .button__inner:after {
      background: $grey-mid;
    }
    svg {
      fill: $grey-mid;
    }
  }
}