.loading {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;

  svg {
    fill: #FFF;
    transform-origin: center;
    animation: loadingRotate 1s linear infinite;
  }
  &--purple svg {
    fill: $purple
  }
  &--blue svg {
    fill: $blue
  }
}

@keyframes loadingRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}