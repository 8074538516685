.list-item {
  padding: 10px 30px;
  margin: 0 -30px;
  &:nth-of-type(even) {
    background-color: $grey-body;
  }
  &__inner {
    @include media-breakpoint-up(xl) {
      display: flex;
      justify-content: space-between;
      //flex-wrap: wrap;
      flex-basis: 100%;
    }
  }
  &__title {
    color: $grey-dark;
    padding-right: 20px;
    @include media-breakpoint-down(lg) {
      padding-right: 30px;
    }

    &-primary {
      margin-bottom: 0;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
    }

    &-secondary {
      font-size: 12px;
      margin: 0;
      line-height: 22px;
    }
  }
  &--no-primary {
    .list-item__title-secondary {
      margin-bottom: 0;
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
    }
  }

  .collapsible-button {
    display: none;
    @include media-breakpoint-down(lg) {
      display: block;
      float: right;
      margin-right: -30px;
    }
  }

  &__actions {
    @include media-breakpoint-down(lg) {
      max-height: 0;
      overflow: hidden;
      @include transitionSimple(max-height);
    }
    &-inner {
      display: flex;
      justify-content: flex-end;
      @include media-breakpoint-down(lg) {
        padding-top: 10px;
        justify-content: flex-start;
      }
      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
      }
    }

    &-link {
      @include text-link-underline();
      margin-right: 20px;
      @include media-breakpoint-down(sm) {
        margin-right: 6px;
      }
      &[disabled] {
        color: $grey-mid;
      }
      &, &-text {
        white-space: nowrap;
        font-size: 12px;
      }
      &:last-child {
        margin-right: 0;
      }

      &--view {
        min-width: 30px;
      }
      &--edit {
        min-width: 24px;
      }
      &--delete {
        @include media-breakpoint-up(xl) {
          min-width: 20px;
          .list-item__actions-link-text {
            display: none;
          }
        }
        @include media-breakpoint-down(lg) {
          min-width: 40px;
          .button__icon {
            display: none;
          }
          .list-item__actions-link-text {
            display: block;
          }
        }
      }

      > .button__loading,
      > .button__icon {
        svg {
          width: 18px;
          height: 18px;
          fill: $blue;
        }
      }
      > .button__icon {
        margin-right: 0;
      }
    }
  }
  &--open {
    .list-item__actions {
      max-height: 40px;
      @include media-breakpoint-down(sm) {
        max-height: 80px;
      }
    }
  }
}