.form__field-select-wrapper {
  position: relative;
  min-width: 150px;
  .form__field-control--select {
    height: 50px;
    background-color: #FFF;
    position: relative;
    padding-right: 40px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: $grey-dark;
    &[disabled] {
      border-color: $grey-mid;
      background-color: $grey-light;
      cursor: not-allowed;
    }
    &:after {
      content: '';
      position: absolute;
      right: 20px;
      top: 20px;
      width: 12px;
      height: 8px;
      background-image: url('../../../images/icon/arrow-down.svg');
      background-repeat: no-repeat;
      background-size: 12px 8px;
      @include transitionSimple(transform, .1s);
    }
    &-open {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  .form__field-select-options {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    top: 58px;
    background: #FFF;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
    z-index: 2;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    @include transitionSimple(max-height, .05s);
    &--open {
      max-height: 300px;
      overflow-y: auto;
    }
    &-list-item {
      padding: 0;
      margin: 0;
      button {
        width: 100%;
        color: $black;
        padding: 10px 20px;
        font-weight: 600;
        &:hover {
          color: $purple;
          background: lighten($grey-light, 3%);
        }
      }
      &--selected {
        background: $grey-light;
        &:hover button {
          color: $purple;
          background: $grey-light;
        }
      }
    }
  }
}