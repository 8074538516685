.header__links {
  display: inline-block;
  float: right;
  @include media-breakpoint-down(lg) {
    display: none;
  }

  &-link {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: $grey-light;
    margin-left: 8px;
    padding-left: 0;
    padding-right: 0;

    &:first-child {
      margin-left: 0;
    }

    svg {
      width: 20px;
      height: 20px;
      margin: 15px;
      fill: $purple;
    }

    &--active {
      background: $blue;

      svg {
        fill: #FFF;
      }
    }

    &:hover {
      background: $purple;

      svg {
        fill: #FFF;
      }
    }
  }
}