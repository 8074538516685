.loading-panel {
  width: 100%;
  height: 100%;
  background: rgba(#FFF, 0.8);
  position: absolute;
  z-index: 10;
  &__inner {
    position: relative;
    height: 100%;
    span.loading {
      width: 80px;
      height: 80px;
      padding: $space;
      position: absolute;
      background: rgba(#FFF, .8);
      border-radius: 50%;
      z-index: 1;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
      @include shadow();
      svg {
        fill: $purple;
      }
    }
  }
}