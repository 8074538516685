.heading-icon {
  margin-right: 16px;
  display: inline-block;
  vertical-align: middle;
  svg {
    fill: $purple;
    width: 24px;
    height: 24px;
  }
}

h1, h2, h3, h4, h5 {
  > a {
    @include link-underline();
  }
}