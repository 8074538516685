@import '../../config/config';

.admin-bar {
  background: $purple;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 40px;
  text-align: center;
  height: 68px;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    padding: 10px 20px;
  }
  @include media-breakpoint-down(xs) {
    padding: 10px;
  }
  p {
    color: #FFF;
    margin-bottom: 0;
    @include media-breakpoint-down(md) {
      font-size: 13px;
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
  &__account {
    display: inline-block;
    width: 46px;
    height: 46px;
    line-height: 42px;
    text-align: center;
    margin-right: 20px;
    border-radius: 50%;
    background: lighten($purple, 15%);
    @include media-breakpoint-down(md) {
      display: none;
    }
    svg {
      fill: #FFF;
    }
  }
}