.form__field-address-lookup-wrapper {
  position: relative;
  &-search {
    float: right;
    margin-top: -50px;
    margin-right: 16px;
    position: relative;
    font-weight: 700;
    @include link-underline();
    svg {
      fill: $blue;
      width: 20px;
      height: 20px;
    }
  }
  .button {
    margin-bottom: 10px;
  }
  .form__field-control--addresslookup {
    padding-right: 130px;
    margin-bottom: 10px;
  }
  .form__field-address-lookup-fields {
    padding-top: $space;
    &--hidden {
      display: none;
    }
  }
  .form__field-address-lookup-list {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    top: 58px;
    background: #FFF;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
    z-index: 1;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    @include transitionSimple(max-height, .05s);
    &--visible {
      max-height: 500px;
      overflow-y: auto;
    }
    &--loading {
      min-height: 120px;
      .form__field-address-lookup-list-item button {
        color: $grey-light;
        pointer-events: none;
      }
    }
    &-item {
      padding: 0;
      margin: 0;
      &--loading {
        position: absolute;
        background: rgba(#FFF, .6);
        height: 100%;
        width: 100%;
        z-index: 1;
        text-align: center;
        color: $purple;
        font-weight: 500;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .form__field-address-lookup-list-item__loading {
          margin: 0 auto 10px auto;
          height: 40px;
          svg {
            fill: $purple;
            width: 40px;
            height: 40px;
          }
        }
      }
      button {
        width: 100%;
        padding: 10px 20px;
        color: $black;
      }
      &--selected {
        background: $grey-light;
      }
    }
  }
}