.collapsible-button {
  width: 24px;
  height: 24px;
  display: block;
  padding: 0;
  svg {
    width: 12px;
    height: 12px;
    margin: 6px;
    fill: $blue;
    transition: transform .2s ease-in-out;
    transform-origin: center;
  }
  &--open svg {
    transform: rotate(180deg);
  }
}