.header {
  @include media-breakpoint-up(xl) {
    position: relative;
    background: none;
    padding: 54px 0;
    box-shadow: none;
  }
  @include media-breakpoint-down(lg) {
    background-color: #fff;
    margin-bottom: 30px;
    padding: 90px $header-padding-x-tablet 15px $header-padding-x-tablet;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 28px;
    padding: 70px $header-padding-x-mobile 11px $header-padding-x-mobile;
  }
  &-title {
    color: $grey-dark;
    padding-right: 12px;
    font-size: 15px;
  }

  &__balance {
    display: inline-block;
    vertical-align: top;
    @include media-breakpoint-up(xl) {
      padding: 0 20px 0 20px;
      line-height: 50px;
    }
    &-link {
      display: inline-block;
    }
    @include media-breakpoint-down(lg) {
      margin-left: 28px;
      margin-right: 5px;
      overflow: auto;
      .header-title {
        display: inline-block;
        width: 80px;
        margin-right: 64px;
        @include media-breakpoint-down(sm) {
          margin: 0;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      margin: 0;
      width: 50%;
      line-height: 50px;
      text-align: right;
    }
    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
      margin: 0;
      text-align: left;
      padding-right: 12px;
    }
    .balance {
      font-weight: 700;
      @include media-breakpoint-down(sm) {
        margin-right: 5px;
      }
    }
  }
}