.address {
  margin-bottom: 0;

  p {
    margin-bottom: 0;
  }

  .mock-data {
    margin: 10px 0;
    min-height: 14px;
  }
}