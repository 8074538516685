.app--layout-full {
  display: flex;
  align-items: stretch;
  @include full-height(min-height);
  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-up(xl) {
      padding-left: $gutter;
      width: $main-width;
      min-width: $main-width;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    .content {
      flex-grow: 2;
      @include media-breakpoint-down(lg) {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        max-width: $max-width-lg;
        margin: auto;
      }
    }
    .footer {
      @include media-breakpoint-down(lg) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}