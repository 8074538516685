.display {
  &--block {
    display: block;
  }
  &--none {
    display: none;

  }
  &--none-mobile {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  &--none-desktop {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}

