.list-item--account {
  position: relative;
  .favourite-button {
    position: absolute;
    right: 30px;
    top: 6px;
    @include media-breakpoint-down(lg) {
      right: 60px;
    }
  }
  .list-item__inner {
    padding-right: 60px;
    .collapsible-button {
      margin-right: -90px;
    }
  }

  .list-item__actions-link  {
    &--view {
      min-width: 80px;
    }
    &--edit {
      min-width: 90px;
    }
    &--delete {
      //min-width: 200px;
    }
  }
}