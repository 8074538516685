.footer {
  align-self: flex-end;
  width: 100%;
  padding: $space 0;

  &__inner {
    p, span {
      font-size: 10px;
      color: $grey-dark;
    }
    &-copy {
      margin-bottom: 0;
      line-height: 15px;
      a {
        color: $purple;
      }
    }
  }
}
//.app--layout-basic {
//  .footer__inner-logos span {
//    padding-top: 15px;
//  }
//}
.app--layout-full, .app--layout-basic {
  .footer__inner-logos span {
      padding-top: 15px;
    @include media-breakpoint-down(sm) {
      max-width: 400px;
      padding-top: 10px;
    }
    @include media-breakpoint-down(xsm) {
      max-width: 200px;
      padding-top: 0;
    }
  }
}