@import '../../config/config';

.basket-message {
  margin: 20px 0;
  h3 {
    margin-bottom: 10px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  &__price-increase {
    color: $red;
  }
  &__price-decrease {
    color: $green;
  }
  img {
    max-width: 100px;
    max-height: 100px;
    height: auto;
    margin-right: $space;
  }
}