.button {
  &--small {
    padding: 8px 6px;
    border-radius: 20px;
    line-height: 14px;
    .button__inner {
      font-size: 13px;
      line-height: 20px;
    }
    .button__icon {
      line-height: 16px;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &--hidden {
    display: none;
  }
  &--display-none {
    display: none;
  }
  &--align-right {
    float: right;
  }
  &--icon {
    padding-left: 10px;
    padding-right: 10px;
    svg, .button__loading, .button__icon {
      margin-right: 0;
    }
  }
  &--header {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
  }

  &--loading:hover {
    cursor: not-allowed;
    .button__inner:after {
      display: none;
    }
  }
}