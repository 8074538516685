@mixin transitionSimple($property: all, $length: .2s, $type: ease-in-out) {
  transition: $property $length $type;
}
@mixin transitionAll() {
  transition: all .2s ease-in-out;
}

@mixin no-transition() {
  transition-duration: 0s;
}
