.favourite-button {
  padding: 4px;
  .button__icon, .button__loading {
    margin-right: 0;
  }
  .button__loading svg {
    fill: $blue;
  }
  svg {
    width: 16px;
    height: 16px;
    fill: $grey-mid;
  }
  &--favourited svg {
    fill: $blue;
  }
}