.app--layout-basic {
  background: #FFF;
  display: flex;
  @include full-height(min-height);
  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include full-height(min-height);
    @include media-breakpoint-up(xl) {
      padding-left: 100px;
      padding-right: 100px;
      width: 600px;
    }
    @include media-breakpoint-down(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }

    .notification {
      flex-grow: -1;
      width: 100%;
      @include media-breakpoint-down(lg) {
        max-width: $max-width-unauthorised-lg;
        margin-left: auto;
        margin-right: auto;
      }
      @include media-breakpoint-down(md) {
        max-width: $max-width-unauthorised-md;
      }
      @include media-breakpoint-down(sm) {
        max-width: $max-width-unauthorised-sm;
      }
    }
    .content {
      flex-grow: 1;
      width: 100%;
      @include media-breakpoint-down(lg) {
        max-width: $max-width-unauthorised-lg;
        margin: auto;
      }
      @include media-breakpoint-down(md) {
        max-width: $max-width-unauthorised-md;
      }
      @include media-breakpoint-down(sm) {
        max-width: $max-width-unauthorised-sm;
      }
    }
    & > .header {
      align-self: flex-start;
    }
    & > .footer {
      align-self: flex-end;
    }
  }
  .form {
    padding: 0;
    box-shadow: none;
  }

  .login-image {
    flex-grow: 1;
    display: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(xl) {
      display: block;
    }
  }
}