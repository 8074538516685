.form--register {
  .account-number {
    border: 1px solid $grey-mid;
  }
}

.form--login, .form--register {
  .info-box .html-content {
    margin-bottom: 16px;
  }
}
