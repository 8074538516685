.header__mobile {
  text-align: right;
  display: none;
  position: fixed;
  background: #FFF;
  top: 0;
  left: 0;
  right: 0;
  z-index: map-get($zindex, header-fixed);
  @include transitionSimple(box-shadow);
  @include media-breakpoint-down(lg) {
    display: block;
    padding: $header-padding-y-tablet $header-padding-x-tablet;
  }
  @include media-breakpoint-down(md) {
    padding: $header-padding-y-mobile $header-padding-x-mobile;
  }
  &--fixed {
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
  }

  &-link {
    vertical-align: top;
    display: inline-block;
    width: 36px;
    height: 36px;
    padding: 5px;
    svg {
      width: 22px;
      height: 22px;
      margin: 2px;
      fill: $purple;
    }
    &--menu {
      padding-top: 7px;
      margin-left: 12px;
      > span {
        display: block;
        margin-bottom: 6px;
        width: 26px;
        height: 2px;
        font-size: 12px;
        background-color: $purple;
        @include transitionSimple(transform);

        &:last-child {
          margin: 0;
        }
      }

    }
  }
}
.body--menu-open .header__mobile-link--menu {
  span {
    &:first-child {
      transform: rotate(-45deg) translate(-3px, 2px);
      margin: 0;
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:last-child {
      transform: rotate(45deg) translate(-5px, -4px);
      margin: 0;
      opacity: 1;
    }
  }

}