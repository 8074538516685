.form__fields {
  &-row {
    @include make-row();

    &-field {
      @include make-col-ready();
      @include make-col-auto();
      flex-grow: 1;

      &--col-6 {
        @include make-col(6);
        @include media-breakpoint-down(sm) {
          @include make-col(12);
        }
      }
      &--col-3 {
        @include make-col(3);
        @include media-breakpoint-down(sm) {
          @include make-col(12);
        }
      }
    }
  }
}
.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $space;
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
  .button--simple:first-child {
    padding-left: 0;
  }
  &--hidden {
    display: none;
  }
  &--submit {
    padding-top: $space;
  }
  & .form__submit-wrapper {
    padding-left: 20px;
    padding-top: 0;
    @include media-breakpoint-down(sm) {
      padding-top: 20px;
      padding-left: 0;
      width: 100%;
    }
  }
  &--submit-buttons {
    padding-top: 10px;
    justify-content: flex-start;
    margin-bottom: 0;
    .button {
      margin-right: 20px;
      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
      }
    }
    & > .button:last-child {
      margin-bottom: 0;
    }
    .form__submit-wrapper {
      padding-left: 0;
      @include media-breakpoint-only(md) {
        margin-right: 20px;
      }
      @include media-breakpoint-down(sm) {
        margin-right: 0;
        padding-top: 0;
      }
    }
  }
  .form__field {
    margin-bottom: 0;
  }
  & > & {
    flex-grow: 1;
  }
}

.form__field {
  margin-bottom: 16px;
  position: relative;

  &-label {
    display: block;
    margin-bottom: 2px;
    font-size: 15px;
    font-weight: 500;
    color: $grey-dark;
    abbr {
      color: $purple;
      vertical-align: top;
    }
    &-after {
      font-size: 13px;
      color: $grey-dark;
      margin-top: -4px;
      margin-bottom: 8px;
    }
  }
  &-before,
  &-after {
    position: absolute;
    top: 39px;
    font-weight: 700;
    color: $purple;
  }
  &-before {
    left: 16px;
  }
  &-after {
    right: 20px;
  }

  &-control { // Date picker plugin doesn't allow a class on the input so this is needed
    padding: 13px 20px;
    border-radius: $border-radius;
    color: $grey-dark;
    border: 1px solid $grey-dark;
    width: 100%;
    font-size: 15px;
    height: 50px;
    line-height: 22px;
    font-weight: 600;
    @include transitionSimple(border);
    &[disabled] {
      border-color: $grey-mid;
      background-color: $grey-light;
      cursor: not-allowed;
    }

    &:not([disabled]):focus, &:not([disabled]):active {
      border-color: $blue;
      box-shadow: $blue;
      outline: none;
    }
    &::placeholder {
      color: $grey-mid;
    }
    &--textarea {
      min-height: 77px;
      height: auto;
    }
    &--radio, &--checkbox {
      width: auto;
    }
    &--percentage {
      padding-right: 40px;
    }
    &--currency {
      padding-left: 34px;
    }
    &__clear-field {
      width: 24px;
      float: right;
      margin-top: -40px;
      margin-right: 12px;
      padding-left: 4px;
      padding-right: 4px;
      position: relative;

      svg {
        fill: $purple;
        width: 16px;
        height: 16px;
      }
    }
  }

  &--invalid {
    .form__field-control {
      border-color: $red;
    }
  }

  &--hidden {
    display: none;
  }
  &-error-message {
    color: $red;
    font-size: 12px;
    margin-top: 8px;
  }
  &-characters-remaining {
    padding-top: 4px;
    font-size: 12px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-datepicker-popper {
  z-index: 80 !important;
}