.info-box {
  max-height: 500px;
  overflow: hidden;
  @include transitionSimple(max-height);
  img {
    max-width: 100%;
    height: auto;
  }
  & > p:last-child, .html-content > p:last-child {
    margin-bottom: 0;
  }

  &__inner {
    padding: $space $space $space 48px;
    margin-bottom: $space;
    background: $grey-light;
    border-radius: $border-radius;
  }
  &--text-small {
    @extend .text-small;
  }
  &--hidden {
    max-height: 0;
  }
  &__icon {
    float: left;
    margin-left: -36px;
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}