$tabsPadding: 20px;
.tabs {
  margin-bottom: 16px;
  position: relative;

  &--mobile-dropdown {
    .tabs-nav {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
  &--pay {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  
  &--nav {
    .tabs-nav {
      &:after {
        content: "";
        position: absolute;
        top: 34px;
        width: 100%;
        background: #B3E6EE;
        height: 1px;
        left: 0;
        z-index: 0;
      }
      
      &__tab {
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: -5px;
          left: 0;
          right: 0;
          background: #B3E6EE;
          height: 1px;
          z-index: 0;
        }
      }
      
      &__tab-indicator {
        height: 3px;
      }
    }
  }

  &-nav {
    list-style: none;
    padding: 0 0 20px 0;
    margin: 0;
    position: relative;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    
    &__tab {
      position: relative;
      display: inline-block;

      &:nth-child(2) {
        margin-left: -$tabsPadding;
      }
      &:last-child .tabs-nav__tab-button {
        padding-right: 0;
      }

      .tabs-nav__tab-button {
        color: $blue;
        font-weight: 700;
        padding: 3px $tabsPadding;
        font-size: 18px;

        &:hover {
          color: $blue-dark;
        }
      }

      &--active {
        .tabs-nav__tab-button {
          color: $purple;

          &:hover {
            color: darken($purple, 10%);
          }
        }
      }
    }

    &__tab-indicator {
      position: absolute;
      height: 2px;
      width: 0;
      bottom: 14px;
      background: $purple;
      left: 0;
      transition: left .15s ease-in-out;
      z-index: 1;
    }
  }

  &--nav {
    @include media-breakpoint-up(xl) {
      margin-right: calc(0px - ((100vw - 1257px) / 2));
    }
  }
  &--inner {
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 34px;
      left: 0;
      height: 2px;
      background: $grey-mid;
    }

    .tabs-nav__tab-button {
      font-size: 15px;
    }
  }

  &-content {
    padding-top: $space;

    &__tab {
      display: none;

      &--active {
        display: block;
      }
    }
  }

  &-dropdown {
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
    @include media-breakpoint-up(md) {
      display: none;
    }

    &__text {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 2px;
    }

    &__button {
      display: block;
      padding: 6px 46px 6px 16px;
      height: 50px;
      color: $purple;
      background: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid $grey-dark;
      border-radius: $border-radius;
      width: 100%;

      .header-title {
        float: left;
        margin-left: -80px;
        @include media-breakpoint-up(xl) {
          line-height: 50px;
        }
      }
      &-text {
        font-size: 15px;
        font-weight: 700;
        color: $purple;
        white-space: nowrap;
        @include transitionSimple(color);
        em {
          display: block;
          font-weight: 700;
          font-style: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-arrow {
        float: right;
        width: 14px;
        margin-right: -28px;
        svg {
          fill: $purple;
          transition: transform .2s ease-in-out;
          transform-origin: center;
          width: 14px;
          height: 8px;
          margin-top: -4px;
        }
      }
    }

    &__menu {
      background: #FFF;
      position: absolute;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
      max-height: 0;
      @include transitionSimple(max-height);
      z-index: map-get($zindex, menu-dropdown);
      overflow: hidden;
      width: 100%;
      top: 77px;

      &-list {
        margin: 0;
        padding: 14px 22px;
        list-style: none;
        overflow-y: auto;
        max-height: calc(100vh - 180px);
        &-item {
          padding: 8px 0;

          &__button {
            max-width: 100%;
            padding-left: 0;
            font-size: 15px;
            font-weight: 700;
            color: $grey-dark;
            em {
              display: block;
              font-weight: 700;
              font-style: normal;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &:hover {
              em, span {
                @include transitionSimple(color);
                &:hover {
                  color: $purple;
                }
              }
            }
          }

          &--active &__button {
            color: $purple;
          }
        }
      }
    }

    &--open {
      .tabs-dropdown__button {
        border-color: $purple;
      }
      .tabs-dropdown__button-arrow svg {
        transform: rotate(180deg);
      }

      .tabs-dropdown__menu {
        max-height: calc(100vh - 140px);
      }
    }
  }
}

.app--layout-basic {
  .tabs-content {
    padding-top: 0;
  }
  .tabs-nav {
    margin-bottom: 10px;
  }
}

//tabs-nav__tab nav__tab--active