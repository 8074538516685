.form--boiler-date {
  .info-box {
    @include media-breakpoint-down(sm) {
      flex-basis: 100%;
      padding-top: 20px;
    }
    &__inner {
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }
  }
}