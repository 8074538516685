.sidenav {
  pointer-events: none;
  @include full-height(min-height);
  @include media-breakpoint-up(xl) {
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    width: calc(((100% - #{$full-width}) / 2) + #{$sidenav-width});
    min-height: 100vh;
  }
  @include media-breakpoint-down(lg) {
    z-index: map-get($zindex, menu);
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: -100%;
    padding-top: 140px;
    @include transitionSimple(left);
  }
  @include media-breakpoint-down(md) {
    padding-top: 130px;
    bottom: 0;
  }
  &__inner {
    pointer-events: auto;
    background: #FFF;
    text-align: left;
    height: 100%;
    padding-bottom: 40px;
    @include media-breakpoint-up(xl) {
      padding-top: 155px;
    }
    @include media-breakpoint-down(lg) {
      padding-top: 40px;
      position: relative;
      overflow-y: auto;
    }
    @include media-breakpoint-down(md) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__list {
    list-style: none;
    margin: 0 0 40px 0;
    padding: 10px 0;
    border-radius: $border-radius;
    background: #FFF;
    @include media-breakpoint-up(xl) {
      padding: 10px 0;
    }
    @include media-breakpoint-down(lg) {
      padding: 0;
    }

    &-item {
      text-align: right;
      &-link {
        display: block;
        background: none;
        width: 100%;
        text-align: left;
        border: none;
        font-size: 15px;
        line-height: 24px;
        font-weight: 700;
        color: $purple;
        padding: 14px 18px;
        @include media-breakpoint-up(xl) {
          margin-left: auto;
          max-width: $sidenav-width;
        }
        @include media-breakpoint-down(lg) {
          padding-left: $header-padding-x-tablet;
        }
        @include media-breakpoint-down(md) {
          padding-left: $header-padding-x-mobile;
        }

        & > span {
          margin-right: 18px;
        }

        svg {
          width: 25px;
          height: 25px;
          fill: $purple;
          @include transitionSimple(fill);
        }

        &:hover {
          color: $blue;
          svg {
            fill: $blue;
          }
        }
      }

      .mock-data {
        margin: 14px 18px;
        @include media-breakpoint-up(lg) {
          max-width: 226px;
          margin: 14px 18px 14px auto;
          text-align: left;
        }
        @include media-breakpoint-down(lg) {
          margin-left: $header-padding-x-tablet;
        }
        @include media-breakpoint-down(md) {
          margin-left: $header-padding-x-mobile;
        }
      }

      &--active {
        background-color: $grey-light;

        .sidenav__list-item-link {
          color: $blue;
          svg {
            fill: $blue;
          }
        }
      }
    }

    &--secondary {
      margin-bottom: 0;

      .sidenav__list-item {
        &:last-child {
          padding-top: 30px;
        }
        &--logout {
          @include media-breakpoint-up(xl) {
            display: none;
          }
        }

        &-link {
          font-size: 12px;
          font-weight: 500;
          padding: 6px 18px;
          @include media-breakpoint-down(lg) {
            padding-left: $header-padding-x-tablet;
          }
          @include media-breakpoint-down(md) {
            padding-left: $header-padding-x-mobile;
          }

          &-icon {
            svg {
              margin-right: 0;
              width: 14px;
              height: 14px;
              fill: $purple;
            }
            &--external {
              margin-right: 0;
              margin-left: 20px;
            }
          }
          &:hover > span svg {
            fill: $blue;
          }
        }
      }
    }
  }
}

.body--menu-open .sidenav {
  left: 0;
  @include media-breakpoint-down(sm) {
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      height: 80px;
      width: 100%;
      background: #FFF;
    }
  }
}

.body--menu-open .tabs-nav {
  overflow-x: hidden;
}