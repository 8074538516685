.form__field-password-toggle {
  width: 24px;
  float: right;
  margin-top: -40px;
  margin-right: 20px;
  padding-left: 0;
  padding-right: 0;
  position: relative;

  svg {
    fill: $purple;
    width: 24px;
    height: 24px;
  }
}
.form__field-password-caps-lock {
  width: 24px;
  float: right;
  margin-top: -40px;
  margin-right: 60px;
  padding-left: 0;
  padding-right: 0;
  position: relative;

  svg {
    fill: $purple;
    width: 24px;
    height: 24px;
  }
}
input.form__field-control--password,
input.form__field-control--passwordstrength {
  padding-right: 90px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
