.form__field-password-strength {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;

  &__item {
    position: relative;
    display: block;
    margin-bottom: 6px;
    font-size: 13px;
    color: $grey-mid;

    &-icon {
      display: inline-block;
      width: 16px;
      height: auto;
      margin-right: 10px;
      fill: $grey-mid;
    }
    &--valid {
      color: $green;
      .form__field-password-strength__item-icon {
        fill: $green;
      }
    }

    &--invalid {
      color: $red;
      .form__field-password-strength__item-icon {
        fill: $red;
      }
    }
  }
}