.live-chat {
  position: fixed;
  bottom: $space;
  right: $space;

  &__button {
    border-radius: 50%;
    background: #FFF;
    color: $blue;
    padding: 0;
    width: 80px;
    height: 80px;
    @include shadow();

    &-inner {
      text-align: center;
      display: block;
      color: $blue;
      font-weight: 500;
      font-size: 11px;
      white-space: nowrap;
      padding-bottom: 6px;
      @include transitionSimple(colour);
    }

    svg {
      width: 30px;
      height: 30px;
      display: block;
      margin: 0 auto 2px auto;
      @include transitionSimple(fill);
      fill: $blue;
    }

    &:not([disabled]):hover {
      cursor: pointer;

      .live-chat__button-inner {
        color: $purple;
      }
    }
  }
}