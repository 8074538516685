.notification {
  width: 100%;
  overflow: hidden;
  @include transitionSimple(all);
  max-height: 200px;
  margin-bottom: $space;
  @include clearfix();
  &__inner {
    font-size: 14px;
    font-weight: 500;
    border-radius: $border-radius;
    background: $grey-light;
    padding: 12px $space 12px 60px;
    @include clearfix();
  }
  &__icon {
    margin: 0 12px 2px -40px;
    width: 24px;
    height: 24px;
    float: left;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .html-content, p {
    @include media-breakpoint-up(lg) {
      float: left;
    }
  }
  p {
    line-height: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &-success, &-info, &-danger {
    color: #FFF;
  }

  &--hidden {
    max-height: 0;
    margin-bottom: 0;
  }
  &--small {
    p {
      font-size: 13px;
      line-height: 20px;
    }
  }
  &--global {
    position: relative;
    overflow: hidden;
    & .notification__inner {
      background: #FFF;
      padding-left: 58px;
    }
    &:before {
      content: '';
      background: $blue;
      left: 0;
      top: 0;
      position: absolute;
      width: 10px;
      height: 100%;
    }
    .notification__icon {
      margin-left: -36px;
    }
    p {
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-down(md) {
        padding-bottom: 10px;
      }
    }
    a {
      color: $blue;
      font-weight: 600;
      position: relative;
      @include media-breakpoint-up(lg) {
        float: right;
      }
      &:after {
        content: '';
        width: 100%;
        position: absolute;
        background: $blue;
        bottom: -2px;
        left: 0;
        height: 2px;
        transition: width .1s ease-in-out, left .1s ease-in-out;
      }
      &:hover {
        color: $blue-dark;
        &:after {
          background: $blue-dark;
        }
      }
    }
    &.notification--warning {
      &:before {
        background: $red;
      }
      p {
        color: $red;
      }
      a {
        color: $red;
        &:after {
          background: $red;
        }
        &:hover {
          color: darken($red, 10%);
          &:after {
            background: darken($red, 10%);
          }
        }
      }
    }
  }
  .form--login & &__inner .html-content a {
    font-weight: bold;
  }
}

.body--unauthenticated .alert {
  max-width: 600px;
  margin: 0 auto $space auto;
}