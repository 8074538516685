::selection {
  background: $purple;
  color: #FFF;
}

a, button {
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
}
button {
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  &:active, &:focus {
    outline: none;
  }
}

p, li, div, span {
  font-size: $font-size;
  line-height: $line-height;
}

h1, h2, h3, h4, h5, h6 {
  a {
    @include text-link-underline();
  }
}

ul {
  margin-bottom: 20px;
  padding-left: 20px;
}