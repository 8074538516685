.pagination {
  @include list-unstyled();

  &--table {
    justify-content: flex-end;
  }
  &--center {
    text-align: center;
  }

  &__item {
    display: inline-block;
    padding: 0 5px;
    &-link {
      display: inline-block;
      color: $grey-dark;
      border: none;
      font-weight: 700;
      padding: 6px 10px;
      font-size: 12px;
      line-height: 1.5;
      svg {
        width: 8px;
        height: 8px;
        fill: $purple;
      }
      &:hover {
        color: $purple;
      }
    }

    &--active {
      > span {
        color: $purple;
        position: relative;
        &:after {
          content: '';
          background: $purple;
          width: calc(100% - 8px);
          position: absolute;
          bottom: 0;
          left: 4px;
          height: 2px;
          transition: width .1s ease-in-out, left .1s ease-in-out;
        }
      }
    }

    &--disabled {
      span {
        color: $grey-mid;
      }
      svg {
        fill: $grey-mid;
      }
    }
  }
}