.form__field-checkbox {
  position: relative;
  input[type='checkbox'] {
    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;
    }

    &:not(:checked) + label,
    &:checked + label {
      vertical-align: top;
      padding-left: 30px;
      cursor: pointer;
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 0;
    }

    /* checkbox aspect */
    &:not(:checked) + label:before,
    &:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border: 1px solid $grey-dark;
      background: #FFF;
      border-radius: $border-radius;
    }

    /* checked mark aspect */
    &:not(:checked) + label:after,
    &:checked + label:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background: url('../../../images/icon/check.svg') no-repeat center;
      background-size: 12px 12px;
      transition: all .2s;
    }

    /* checked mark aspect changes */
    &:not(:checked) + label:after {
      opacity: 0;
      background: none;
      transform: scale(0);
    }

    &:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }

    &:checked:focus + label:before,
    &:not(:checked):focus + label:before {
      border-color: $blue;
    }

    &:disabled + label:before {
      border-color: $grey-mid;
      cursor: not-allowed;
    }
    &:disabled + label:after {
      background-color: $grey-light;
    }
  }
}