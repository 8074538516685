@use "sass:math";

.logo {
  position: absolute;
  margin-left: auto;
  display: block;
  width: 145px;
  height: 50px;
  z-index: map-get($zindex, logo);
  @include media-breakpoint-only(xl) {
    top: 55px;
    left: calc(50% - (math.div($full-width, 2) - 18px));
  }
  @include media-breakpoint-down(lg) {
    top: $header-padding-y-tablet;
    left: $header-padding-x-tablet;
    position: fixed;
    width: 106px;
    height: 35px;
  }
  @include media-breakpoint-down(md) {
    top: $header-padding-y-mobile;
    left: $header-padding-x-mobile;
    width: 101px;
    height: 33px;
  }
  svg {
    width: 145px;
    height: 50px;
    @include media-breakpoint-down(lg) {
      width: 106px;
      height: 35px;
    }
    @include media-breakpoint-down(md) {
      width: 101px;
      height: 33px;
    }
  }
}