.unauthorised-header {
  padding: 55px 0;
  margin-bottom: 63px;
  @include media-breakpoint-down(lg) {
    padding: 47px 0;
    margin-bottom: 40px;
  }
  @include media-breakpoint-down(sm) {
    padding: 37px 0;
    margin-bottom: 0;
  }

  &__logo {
    float: left;

    &, svg {
      width: 144px;
      height: 50px;
      @include media-breakpoint-down(sm) {
        width: 116px;
        height: 40px;
      }
    }
  }

  &__strapline {
    float: right;
    text-align: right;
    color: $purple;
    font-size: 30px;
    line-height: 50px;
    margin-bottom: 0;
    @include media-breakpoint-down(lg) {
      font-size: 28px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 26px;
      line-height: 40px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 24px;
    }
  }
}