@import '../../config/config';

@media print {
  .body {
    margin: 0;
  }
  #modal-wrapper,
  .splash-screen,
  .sidenav,
  .pagination,
  .button,
  a.button,
  .table-cell--button,
  .form,
  .notification,
  .tabs-nav,
  .live-chat,
  .box__buttons,
  .no-print,
  .table-footer__back-next,
  .tabs-dropdown,
  .footer {
    display: none;
  }
  .table-title {
    display: block;
    margin-bottom: 1cm;
  }
  .header {
    padding: 0 0 0 8cm;
    background: none;
    border-bottom: 2px solid $grey-mid;
    margin-bottom: .8cm;
    width: 100%;
    display: flex;
    .balance {
      color: $purple;
    }
    &__mobile,
    &__search,
    &__account-button-arrow,
    &__links {
      display: none;
    }
    &__account {
      &-button {
        padding: 0;
        border: none;
      }
      .header-title {
        display: none;
      }
      &-button-name {
        width: 100%;
        em {
          text-overflow: unset;
        }
      }
    }
    &__balance {
      width: 70%;
      float: right;
      line-height: 40px;
    }

    a {
      text-decoration: none;
    }
  }
  .table .table-cell:first-child {
    padding-left: 0;
  }
  .app {
    width: 100%;
  }
  .main {
    display: block;
    padding: .5cm 0;
    width: 100% !important;
    min-height: 0;
  }
  .content {
    max-width: 100%;
    padding: 0;
  }
  .body .app {
    width: 100%;
    min-height: 0;
  }
  .box {
    padding: 0;

    .table-wrapper {
      margin-left: 0;
      margin-right: 0;
    }
  }
}