.svg-purple {
  fill: #4C2177;
}

.svg-white {
  fill: #FFF;
}

.svg-blue {
  fill: #00ABC8;
}