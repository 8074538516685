@mixin link-underline() {
  position: relative;
  color: $blue;
  text-decoration: none;
  &:after {
    content: '';
    background: $blue;
    width: 100%;
    left: 0;
    position: absolute;
    bottom: -2px;
    height: 2px;
  }
  &:hover {
    color: $blue-dark;
    &:after {
      background: $blue-dark;
    }
  }
}
@mixin text-link-underline() {
  color: $blue;
  text-decoration: underline;
  &:hover {
    color: $blue-dark;
    &:after {
      background: $blue-dark;
    }
  }
}