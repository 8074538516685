.table {
  width: 100%;
  border-radius: $border-radius;

  th, td {
    font-size: 15px;
    padding: 8px 18px;
  }

  &-title {
    display: none;
  }

  &-wrapper {
    background: #FFF;
    border-radius: $border-radius;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: $space;
    @include media-breakpoint-up(md) {
      min-height: 100px;
      overflow-x: auto;
    }

    &--in-box-normal {
      margin-left: -20px;
      margin-right: -20px;
    }

    &--in-box-large {
      margin-left: -30px;
      margin-right: -30px;
      @include media-breakpoint-only(md) {
        margin-left: -26px;
        margin-right: -26px;
      }
      @include media-breakpoint-down(sm) {
        margin-left: -20px;
        margin-right: -20px;
      }
    }
  }

  tr {
    @media screen {
      @include media-breakpoint-down(sm) {
        display: block;
        padding: 14px 20px;
      }
    }
  }

  &--striped {
    tbody tr:nth-of-type(even) {
      background-color: $grey-body;
    }
  }
  tr.table-row-selected:nth-of-type(odd) {
    background-color: rgba($blue, .04);
  }
  tr.table-row-selected:nth-of-type(even) {
    background-color: rgba($blue, .1);
  }

  &--loading {
    @include media-breakpoint-down(sm) {
      tbody tr {
        display: none;
        &:first-child {
          display: block;
        }
      }
    }
  }
  .table-head-sorter {
    width: 100%;
    font-weight: 600;
    text-align: left;

    svg {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      vertical-align: top;
    }
  }

  thead tr {
    @media screen {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__loading {
    width: 80px;
    height: 80px;
    padding: $space;
    position: absolute;
    background: rgba(#FFF, .8);
    border-radius: 50%;
    z-index: 1;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    @include shadow();

    svg {
      fill: $purple;
    }
  }
  &__error {
    width: 260px;
    padding: $space;
    position: absolute;
    background: #FFF;
    border-radius: $border-radius;
    z-index: 3;
    top: calc(50% - 38px);
    left: calc(50% - 130px);
    font-weight: 500;
    @include shadow();

    svg {
      width: 26px;
      height: 26px;
      display: inline-block;
      margin-right: 14px;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 0 20px;
    &__back-next {
      @include media-breakpoint-up(lg) {
        margin-left: auto;
      }
      &-page {
        margin-right: 40px;
      }
      .button {
        margin-left: 10px;
      }

    }
  }
  &-image-wrapper {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      margin-top: 8px;
    }
    &__image {
      width: 50px;
      height: 50px;
      border: 1px solid $grey-mid;
      border-radius: $border-radius;
      padding: 4px;
      text-align: center;
      margin-right: 8px;
      background: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: auto;
        max-width: 100%;
        max-height: 40px;
      }
    }
  }
}

.table-wrapper--in-box-large {
  tr {
    @include media-breakpoint-down(sm) {
      padding: 14px 30px;
    }
  }
}

.button--table-footer {
  margin-right: 12px;
}