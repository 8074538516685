// Table cell modifiers
.table .table-cell {
  color: $grey-dark;
  &:first-child {
    @include media-breakpoint-up(md) {
      padding-left: 24px;
    }
  }
  &:last-child {
    @include media-breakpoint-up(md) {
      padding-right: 24px;
    }
  }
  @media screen {
    @include media-breakpoint-down(sm) {
      display: block;
      position: relative;
      &, &:first-child {
        padding: 16px 0 5px 0;
      }
    }
  }
  &--button {
    white-space: nowrap;
    .button, .table-button {
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
      &[disabled] {
        &, &:hover {
          color: $grey-mid;
        }
      }
    }
  }

  &--button-single {
    width: 30px;
    margin: 0;
    display: inline-block;

    &:first-child {
      padding-left: 18px;
    }
  }
  &--button a, &--button-single a,
  .table-button {
    @include text-link-underline();
  }

  &--amount {
    @include media-breakpoint-up(md) {
      width: 120px;
      max-width: 120px;
      text-align: right;
    }
  }

  &--quantity {
    @include media-breakpoint-up(md) {
      width: 98px;
      max-width: 98px;
      text-align: center;
    }
  }

  &--balance {
    @include media-breakpoint-up(md) {
      width: 160px;
      max-width: 160px;
      text-align: right;
    }
  }
  &--total {
    font-weight: bold;
  }
  &--date {
    min-width: 120px;
  }
  &--checkbox {
    width: 20px;
    label {
      padding-left: 20px !important;
    }
  }
  .table-button {
    @media print {
      display: none;
    }
    .button__loading svg {
      fill: $blue;
    }
    &--viewed {
      color: $blue-dark;
    }
  }
}

th.table-cell {
  font-weight: 600;
}

.table tbody .table-cell {
  @media screen {
    @include media-breakpoint-down(sm) {
      &:before {
        content: attr(title);
        position: absolute;
        left: 0;
        top: 0;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
  &--date,
  &--amount,
  &--label-left {
    @media screen {
      @include media-breakpoint-down(sm) {
        &, &:first-child, &:last-child {
          min-height: 28px;
          padding: 0 0 5px 0;
          text-align: right;
          &:before {
            left: 0;
            line-height: 24px;
          }
        }
      }
    }
  }
}
.table tfoot {
  tr {
    @media screen {
      @include media-breakpoint-down(sm) {
        display: flex;
        justify-content: space-between;
      }
    }
  }

}

.table tfoot .table-cell--label {
  @include media-breakpoint-up(md) {
    text-align: right;
  }
}

.table-wrapper--in-box-large {
  .table-cell:first-child {
    @include media-breakpoint-up(md) {
      padding-left: 30px;
    }
  }
  .table-cell:last-child {
    @include media-breakpoint-up(md) {
      padding-right: 30px;
    }
  }
}