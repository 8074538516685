.link {
  color: $purple;
  display: inline-block;
  padding: 0;
    border-bottom: 1px solid $purple;
  &:hover {
    color: darken($purple, 10%);
    border-color: darken($purple, 10%);
  }
  &--blue {
    color: $blue;
    border-color: $blue;
    &:hover {
      color: $blue-dark;
      border-color: $blue-dark;
    }
  }
}