.button--full {
  padding: 10px 50px 12px 50px;
  border-radius: 25px;
  @include media-breakpoint-down(xs) {
    padding-left: 30px;
    padding-right: 30px;
  }
  &.button--small {
    padding: 8px 16px;
  }
  .button__inner {
    color: #FFF;
    font-weight: 500;
    &:after {
      background-color: #FFF;
    }
  }

  @each $colourName, $colour in $buttonColours {
    &.button--#{"" + $colourName} {
      background: $colour;

      &:hover {
        background: darken($colour, 10%);
      }
    }
  }
}