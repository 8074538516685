$grey-body: #F4F4F4;
$grey-light: #F0F0F0;
$grey-mid: #D7D5D5;
$grey-dark: #626262;
$black: $grey-dark;
$blue: #00B3CF;
$blue-dark: #00778A;
$purple: #4C2177;
$purple-dark: #260F46;
$green: #78BE21;
$yellow: #FFB81C;
$red: #DC5656;