.form__field-radios {
  padding-top: 8px;
  .form__field-radio {
    margin-bottom: $space;
  }
  &--inline {
    padding: 0;
    .form__field-radio {
      margin: 8px 0;
      display: inline-block;
      margin-right: $space;
    }

  }
}
.form__field-radio {
  position: relative;
  input[type='radio'] {
    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;
    }

    &:not(:checked) + label,
    &:checked + label {
      padding-left: 34px;
      cursor: pointer;
      line-height: 24px;
      margin-bottom: 0;
    }

    /* checkbox aspect */
    &:not(:checked) + label:before,
    &:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 24px;
      height: 24px;
      border: 1px solid $grey-dark;
      background: #FFF;
      border-radius: 50%;
    }

    /* checked mark aspect */
    &:not(:checked) + label:after,
    &:checked + label:after {
      content: '';
      position: absolute;
      top: 6px;
      left: 6px;
      width: 12px;
      height: 12px;
      background: $blue;
      border-radius: 50%;
      transition: all .2s;
    }

    /* checked mark aspect changes */
    &:not(:checked) + label:after {
      opacity: 0;
      background: none;
      transform: scale(0);
    }

    &:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }

    &:checked:focus + label:before,
    &:not(:checked):focus + label:before {
      border-color: $blue;
    }

    &:disabled + label:before {
      border-color: $grey-mid;
      cursor: not-allowed;
    }
    &:disabled + label:after {
      background-color: $grey-light;
    }
  }
}