@import '../../config/config';

.cookie-banner {
  z-index: 999;
  bottom: 24px;
  right: 24px;
  position: fixed;
  max-width: 348px;
  @include media-breakpoint-down(sm) {
    right: 0;
    bottom: 0;
    left: 0;
    max-width: inherit;
  }
  &--closed {
    bottom: 24px;
    right: 24px;
    left: inherit;
    position: fixed;
    max-width: 348px;
  }
  &__panel {
    background: #FFF;
    padding: 24px;
    box-shadow: 0 1px 5px rgba(51, 51, 51, 0.3);
    border-radius: 8px;
    &--initial {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    
      h3, p {
        width: 100%;
        text-align: left;
      }
    }
    
    &__buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      .button {
        display: block;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
      }
    }
    
    h3 {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: -.2px;
    }
    
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: .25px;
      color: #333;
      
      button {
        color: $blue;
        transition: color 0.25s ease;
        
        &:hover, &:focus {
          color: $purple;
        }
      }
    }
  
    .button.button--full.button--purple {
      padding: 10px 32px;
      border-radius: 50px;
    }
    
    .button.button--simple.button--purple {
      margin-left: 35px;
      
      @include media-breakpoint-down(sm) {
        margin-left: 12px
      }
    }
    
    &--options {
      h3 {
        text-align: left;
      }
    }
    
    &--hidden {
      display: none;
    }
  }
  .cookie-banner__options-option > p {
    font-weight: 400;
  }
  label.cookie-banner__options-option {
    font-size: 20px;
    line-height: 24px;
    color: $purple;
    font-weight: 500;
    letter-spacing: -.2px;
    padding-right: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    @include media-breakpoint-down(md) {
      font-size: 16px
    }
  }
  &__options-option {
    .cookie-banner__options-option-input-wrapper {
      display: inline-block;
      height: 20px;
      width: 34px;
      background-color: #fff;
      border: 1px solid #CCC;
      border-radius: 25px;
      position: relative;
      vertical-align: middle;
      margin-left: 8px;
      
      .cookie-banner__options-option-input-text {
        display: block;
        position: relative;
        left: 38px;
        line-height: 20px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: .25px;
        color: #4d4d4d;
        text-transform: uppercase;
      }
      
      input {
        display: none;
        
        ~ .cookie-banner__options-option-input-dot {
          position: absolute;
          appearance: none;
          width: 14px;
          height: 14px;
          background: $blue;
          display: block;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50%;
          left: 3px;
          transition: left .25s ease, background .25s ease;
        }
        
        ~ .cookie-banner__options-option-input-text:before {
          display: block;
          position: relative;
          content: "Off";
        }
        
        &:checked {
          ~ .cookie-banner__options-option-input-dot {
            left: 16px;
            background: $purple;
          }
          
          ~ .cookie-banner__options-option-input-text:before {
            content: "On";
          }
        }
        
        &:disabled {
          ~ .cookie-banner__options-option-input-dot {
            background-color: #ccc;
          }
        }
      }
    }
  }
}