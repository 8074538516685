.form--account-name {
  .form__field-error-message {
    margin-bottom: 20px;
  }
  .form-info-box-row {
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .form-info-box-row__fields {
    min-width: 260px;
  }
  .form__submit-wrapper {
    @include media-breakpoint-up(lg) {
      //margin-top: -20px;
      padding-top: 0;
    }
  }
  .info-box__inner {
    margin-bottom: 0;
  }
}