$full-width: 1240px;
$sidenav-width: 260px;
$gutter: 60px;
$main-width: $full-width - $sidenav-width;

$max-width-lg: 960px;

$max-width: 2560px;
$max-width-unauthorised-lg: 540px;
$max-width-unauthorised-md: 390px;
$max-width-unauthorised-sm: 390px;

$header-padding-x-tablet: 30px;
$header-padding-y-tablet: 27px;

$header-padding-x-mobile: 20px;
$header-padding-y-mobile: 17px;