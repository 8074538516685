.splash-screen {
  background: #FFF;
  position: fixed;
  height: 100%;
  max-height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: map-get($zindex, splash-screen);
  overflow: hidden;
  transition: max-height .4s ease-in-out;

  &--loaded {
    max-height: 0;
  }
  &__inner-message {
    max-width: 560px;
    text-align: left;
    border-radius: $border-radius;
    border: 1px solid $grey-mid;
    padding: 20px;
    *:last-child {
      margin-bottom: 0;
    }
  }
  &__inner-logo svg {
    display: block;
    width: 200px;
    height: 65px;
    margin: auto auto 28px auto;
  }
  &__message {
    margin-top: $space;
    color: $purple;

    > p {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &--maintenance {
    text-align: center;
  }

  .loading {
    width: 38px;
    height: 38px;
    svg {
      fill: $blue;
    }
  }
}