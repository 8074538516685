.form {
  width: 100%;
  @include box();
  padding: 30px;
  margin-bottom: $space;
  @include media-breakpoint-only(md) {
    padding: 36px 26px;
  }
  @include media-breakpoint-down(sm) {
    padding: 30px 20px;
  }
  &--in-box {
    max-width: 580px;
    padding: 0;
    margin-bottom: 0;
  }
  &__autocomplete {
    height: 0;
    overflow: hidden;
  }
  &--hidden {
    display: none;
  }
  .form-info-box-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
    .info-box {
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        max-width: 260px;
        margin-left: 45px;
      }
      &--account-details-first {
        margin-bottom: 10px;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
      &--account-details-second {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
    &__fields {
      flex-grow: 1;
      @include media-breakpoint-up(md) {
        max-width: calc(100% - 305px);
        .info-box {
          max-width: none;
          margin-left: 0;
        }
      }
    }
  }
  &__info-icon {
    float: right;
    position: relative;
    margin-top: -40px;
    margin-right: 16px;
    &, & svg {
      width: 23px;
      height: 23px;
    }
  }

  &__submit-wrapper {
    padding-top: $space-small;
    .button {
      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
      }
    }
    &--align-right {
      text-align: right;
      flex-grow: 1;
    }
    &--hidden {
      display: none;
    }
  }
}

.modal-content__body .form-info-box-row__fields {
  max-width: 100%;
}

@import 'modifiers/form--filter';
@import 'modifiers/form--modal';
@import 'modifiers/form--account-name';
@import 'modifiers/form--boiler-date';
@import 'modifiers/form--login';
@import 'modifiers/form--register';
@import 'modifiers/form--marketing-preferences';
@import 'modifiers/form--admin-search';
@import 'form-fields';
@import 'fields/form-field-checkbox';
@import 'fields/form-field-radio';
@import 'fields/form-field-password';
@import 'fields/form-field-password-strength';
@import 'fields/form-field-dropdown';
@import 'fields/form-field-category';
@import 'fields/form-field-filter';
@import 'fields/form-field-address-lookup';
