.mock-data {
  opacity: 0.4;
  border-radius: 2px;
  margin: 2px 0;

  &__inner {
    display: block;
    background: $grey-mid;
    min-height: 20px;
    border-radius: 2px;
  }

  &--paragraph .mock-data__inner,
  &--randomise .mock-data__inner {
    display: inline-block;
    min-width: 20px;
  }

  &--inline {
    min-width: 50px;
    display: inline-block;
    vertical-align: middle;
  }

  &--round {
    .mock-data__inner {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  &--paragraph {
    max-width: 80%;
    margin-bottom: 20px;

    .mock-data__inner {
      margin-right: 6px;
      min-height: 16px;
    }
  }
}

h1, h2, h3, h4 {
  .mock-data {
    margin-left: 10px;
    vertical-align: middle;
    min-width: 100px;
    display: inline-block;
  }
}
h1.mock-data .mock-data__inner {
  min-height: 28px;
}

h2.mock-data .mock-data__inner {
  min-height: 24px;
}