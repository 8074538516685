// Set Bootstrap defaults
$primary: $purple;
$secondary: $blue;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $grey-light;
// Grid / container
$grid-gutter-width: 32px;
$grid-breakpoints: (
        xs: 0,
        xsm: 375px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1280px,
        //xxl: 1432px
);
$container-max-widths: (
        xsm: 375px,
        sm: 540px,
        md: 736px,
        lg: 960px,
        xl: 1280px,
        //xxl: 1432px
);
// Typography
$font-family-base: $font-family;
$body-color: $black;
$paragraph-margin-bottom: 20px;
// Headings
$headings-font-family: $font-family-heading;
$headings-color: $purple;
$headings-font-weight: 700;
$headings-margin-bottom: 16px;

// Links
$link-color: $purple;
$link-hover-color: darken($purple, 15%);
$link-hover-decoration: none;
// Misc
$border-radius: 3px;
// Forms
$input-padding-x: .6rem;
$input-padding-y: .8rem;
$input-border-color: $grey-light;
$input-border-width: 2px;
$input-focus-border-color: $blue;
$input-btn-focus-color: rgba($blue, .25);
//$input-focus-box-shadow: none;
$btn-padding-x: 2rem;
$btn-padding-y: .8rem;
$btn-transition: all .8s ease-in-out;
$alert-bg-level: 0;
$alert-border-level: 0;
$alert-color-level: 10;