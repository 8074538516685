[data-tooltip] {
    position: relative;

    &:hover {
        &:after {
            content: attr(data-tooltip);
            visibility: visible;
            opacity: 1;
            display: block;
            position: absolute;
            bottom: -6px;
            background: #000;
            padding: 5px 15px;
            color: #fff;
            left: 50%;
            transform: translate(-50%, 100%);
            border-radius: 3px;
            white-space: nowrap;
        }

        &:before {
            content: "";
            visibility: visible;
            opacity: 1;
            display: block;
            border: 6px solid #000;
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
            position: absolute;
            bottom: -6px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &:after,
    &:before {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out;

        &:hover {
            opacity: 1;
            visibility: visible;
        }
    }
}
