.header__account {
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  display: inline-block;
  @include media-breakpoint-up(xl) {
    width: 400px;
  }
  @include media-breakpoint-down(lg) {
    margin-bottom: 7px;
    width: 50%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &-button {
    display: block;
    padding: 0 46px 0 96px;
    height: 50px;
    color: $purple;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid $grey-dark;
    border-radius: $border-radius;
    width: 100%;
    @include media-breakpoint-up(md) {
      max-width: 500px;
    }
    @include media-breakpoint-up(xl) {
      line-height: 50px;
      //background: $grey-light;
    }
    @include media-breakpoint-down(lg) {
      display: inline-block;
      width: 100%;
      min-width: 380px;
    }
    @include media-breakpoint-down(md) {
      padding-top: 6px;
      padding-bottom: 6px;
      min-width: 0;
    }

    .header-title {
      float: left;
      margin-left: -80px;
      @include media-breakpoint-up(xl) {
        line-height: 50px;
      }
    }
    &--alias {
      line-height: 1;
      height: 60px;
      @include media-breakpoint-up(xl) {
        padding-top: 9px;
      }
      .header-title {
        line-height: 19px;
      }
      .header__account-button-arrow {
        margin-top: 0;
      }
    }
    &-name {
      font-size: 15px;
      font-weight: 700;
      color: $purple;
      white-space: nowrap;
      @include transitionSimple(color);
      em {
        display: block;
        font-weight: 700;
        font-style: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &--alias {
        line-height: 15px;
        em {
          line-height: 19px;
        }
        span {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
    &-arrow {
      float: right;
      width: 14px;
      margin-right: -34px;
      @include media-breakpoint-up(xl) {
        margin-top: 12px;
      }
      @include media-breakpoint-down(lg) {
        margin-right: -28px;
      }
      svg {
        fill: $purple;
        transition: transform .2s ease-in-out;
        transform-origin: center;
        width: 14px;
        height: 8px;
        margin-top: -4px;
      }
    }
  }

  &-menu {
    background: #FFF;
    position: absolute;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    max-height: 0;
    @include transitionSimple(max-height);
    z-index: map-get($zindex, menu-dropdown);
    overflow: hidden;
    width: 100%;
    top: 68px;
    @include media-breakpoint-down(lg) {
      top: 60px;
    }
    &--alias {
      top: 78px;
      @include media-breakpoint-down(lg) {
        top: 70px;
      }
    }
    @include media-breakpoint-up(md) {
      max-width: 500px;
    }


    &-search {
      padding: 14px 22px;
      &__input {
        padding-right: 40px;
      }
    }

    &-list {
      margin: 0;
      padding: 0 22px 14px;
      list-style: none;
      overflow-y: auto;
      max-height: calc(100vh - 258px);
      &-item {
        padding: 8px 0;
        &:hover {
          .header__account-menu-list-item-link {
            color: darken($purple, 10%);
          }
        }

        button {
          max-width: 100%;
          @include media-breakpoint-down(lg) {
            padding-left: 0;
          }
        }

        &--account {
          padding-right: 30px;
        }

        &--heading {
          font-size: 12px;
          padding: 16px 0;
        }

        &--add {
          padding: 16px 0;
        }

        &-link {
          font-size: 15px;
          font-weight: 700;
          color: $grey-dark;
          em {
            display: block;
            font-weight: 700;
            font-style: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &--current {
            color: $purple;
          }
          &--alias {
            span {
              font-size: 12px;
              font-weight: normal;
            }
          }
        }
        button.header__account-menu-list-item-link {
          &:hover {
            em, span {
              @include transitionSimple(color);
              &:hover {
                color: $purple;
              }
            }
          }
          .button__loading svg {
            fill: $purple;
          }
        }
      }
    }
  }

  &--open {
    .header__account-button {
      border-color: $purple;
    }
    .header__account-button-arrow svg {
      transform: rotate(180deg);
    }

    .header__account-menu {
      max-height: calc(100vh - 140px);
    }
  }

  .favourite-button {
    float: right;
    margin-right: -30px;
    margin-top: -5px;
  }
}