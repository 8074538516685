.form--marketing-preferences {
  width: 100%;
  .form-row {
    justify-content: normal;
    margin-bottom: 0;
    flex-wrap: wrap;
    align-items: flex-start;
    .form__field {
      padding-right: 20px;
      padding-bottom: 20px;
      width: 33.33333%;
      /*&:last-child {
        padding-right: 0;
      }*/
      @include media-breakpoint-down(sm) {
        width: 50%;
        padding-right: 0;
        padding-bottom: 10px;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
  }
}