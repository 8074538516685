.header__search {
  display: none;
  position: absolute;
  z-index: 80;
  @include media-breakpoint-up(xl) {
    background: $grey-body; // To cover text behind
    width: 744px; // Size of header__links + 10px
    height: 60px;
    &--switch {
      width: 686px;
    }
  }
  @include media-breakpoint-down(lg) {
    z-index: map-get($zindex, 'menu-dropdown');
    background: #FFF;
    display: block;
    left: -100%;
    top: 90px;
    padding: 0 $header-padding-x-tablet;
    @include transitionSimple(left);

    &:after {
      content: '';
      width: 100%;
      position: absolute;
      height: 40px;
      left: 0;
      top: 50px;
      background: linear-gradient(180deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%);
    }
    @include media-breakpoint-down(md) {
      &:after {
        height: 30px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    top: 70px;
    padding: 0 $header-padding-x-mobile;
  }
  &--open {
    @include media-breakpoint-up(xl) {
      display: block;
    }
  }

  &-input {
    background: #FFF;
    color: $black;
    width: 100%;
    font-size: 15px;
    height: 50px;
    line-height: 22px;
    font-weight: 600;
    border-radius: 25px;
    &:focus {
      border-color: $blue;
    }
    &::placeholder {
      color: $grey-mid;
    }
    @include media-breakpoint-up(xl) {
      padding: 12px 20px;
      border: 2px solid $blue;
    }
    @include media-breakpoint-down(lg) {
      //border-radius: 3px;
      padding: 13px 40px 13px 20px;
      border: 1px solid $grey-dark;
    }
    @include media-breakpoint-down(sm) {
      padding: 14px 40px 14px 20px;
    }

    &:focus, &:active {
      outline: none;
    }
  }
  &-icon {
    @include media-breakpoint-up(xl) {
      display: none;
    }
    @include media-breakpoint-down(lg) {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 10px;
      right: 50px;
      svg {
        fill: $purple;
      }
    }
    @include media-breakpoint-down(md) {
      right: 40px;
      //right: $header-padding-x-mobile + 12px;
    }
  }

  &-results {
    position: absolute;
    background: #FFF;
    margin: 0;
    left: 0;
    right: 0;
    list-style: none;
    max-height: 50vh;
    overflow-y: auto;
    border-radius: 3px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    height: auto;
    @include media-breakpoint-up(xl) {
      top: 58px;
      padding: 24px 0;
    }
    @include media-breakpoint-down(lg) {
      top: 68px;
      padding: 12px 0;
      left: $header-padding-x-tablet;
      right: $header-padding-x-tablet;
      max-height: calc(100vh - 200px);
    }
    @include media-breakpoint-down(md) {
      //bottom: 74px;
      left: $header-padding-x-mobile;
      right: $header-padding-x-mobile;
      max-height: calc(100vh - 180px);
    }

    &-list-item {
      &--active {
        background: $grey-light;
      }

      &--loading {
        padding: 20px 30px;
        text-align: center;
        svg {
          width: 36px;
          height: 36px;
        }
      }
      &--no-results {
        padding: 10px 30px;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }

      &-link {
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        color: $grey-dark;
        padding: 10px 30px;
        display: block;
        &-type {
          font-weight: 500;
          color: $grey-mid;
          float: right;
          padding-left: 20px;
        }
      }
    }
  }
}
.body--menu-open .header__search {
  &, &--open {
    @include media-breakpoint-down(lg) {
      left: 0;
      width: 100%;
      height: 70px;
    }
  }
}