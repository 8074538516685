h1, .h1 {
  @include media-breakpoint-down(md) {
    font-size: 28px;
    line-height: 35px;
  }
}
.text-small {
  &, & > p, & .html-content > p {
    font-size: 12px;
    line-height: 17px;
  }
}
.text-purple {
  color: $purple;
}
p.reduced-line-height {
  line-height: 18px;
}