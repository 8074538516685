@use "sass:math";

.box {
  @include box();
  margin-bottom: $space;
  &--hide {
    display: none;
  }

  &--padding-none {
    padding: 0;
  }

  &--padding-large {
    padding: 30px;
    @include media-breakpoint-only(md) {
      padding: 36px 26px;
    }
    @include media-breakpoint-down(sm) {
      padding: 30px 20px;
    }
  }
  > p:last-child {
    margin-bottom: 0;
  }

  &__buttons {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    .button {
      margin-right: 10px;
      &--no-padding {
        margin-right: 20px;
      }
      @include media-breakpoint-down(md) {
        &.button--full {
          width: 100%;
          display: block;
          text-align: center;
          margin-bottom: 10px;
          margin-right: 0;
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $space;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    .box__buttons {
      @include media-breakpoint-up(lg) {
        white-space: nowrap;
        .button:last-child {
          margin-right: 0;
        }
      }
    }

    h1, h2, h3, h4, h5 {
      margin-bottom: 0;
    }
  }

  &__info {
    &--cols-3 {
      display: flex;
      justify-content: space-between;
      &.box__info-item {
        max-width: percentage(math.div(1, 3));
        flex-basis: percentage(math.div(1, 3));
      }
    }
    &-item {
      margin-bottom: $space;
      .mock-data {
        display: inline-block;
        min-width: 120px;
        vertical-align: middle;
      }
    }
  }

  &--loading {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;

    .loading {
      width: 80px;
      height: 80px;
      margin: auto;
      padding: $space;
    }
  }

  &--loading-overlay {
    position: relative;
    min-height: 100%;
    .loading-panel {
      z-index: 1;
      inset: 0;
    }
  }
  .table-wrapper:last-child {
    margin-bottom: 0;
  }
}

.col > .box {
  height: calc(100% - 20px);
}