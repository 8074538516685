.hero {
  margin: 0 auto 40px auto;
  @include media-breakpoint-down(dm) {
    margin-bottom: 10px;
  }
  &--auto-register {
    h1 {
      font-size: 50px;
      font-weight: 500;
      line-height: 1.2;
    }

    & + .html-content h2 {
      font-size: 20px;
      font-weight: 500;
    }
  }
  @include media-breakpoint-up(lg) {
    &--auto-register {
      h1 {
        font-size: 64px;
        line-height: 1;
      }
    }
  }
}