.button {
  &--outline.button--full {
    border: 2px solid;

    @each $colourName, $colour in $buttonColours {
      &.button--#{"" + $colourName} {
        background: #FFF;
        border: 2px solid $colour;
        .button__inner {
          color: $colour;
        }
        svg {
          fill: $colour;
        }

        &:hover {
          background: #FFF;
          border-color: darken($colour, 10%);
          .button__inner {
            color: darken($colour, 10%);
            &:after {
              background-color: darken($colour, 10%);
            }
          }
          svg,
          .loading {
            fill: darken($colour, 10%);
          }
        }
      }
    }
  }
  &--outline.button--full.button--small,
  &--outline.button--small {
    padding: 6px;
  }
}