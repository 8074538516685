.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  @include transitionSimple(opacity .4s);

  &-shroud {
    height: 100%;
    background: rgba(0, 0, 0, .4);
    text-align: center;
  }

  &--global {
    z-index: 110;
  }

  &--autologout {
    z-index: 120;
  }

  &-content {
    @include box();
    @include vertical-align-center();
    display: inline-block;
    text-align: left;
    width: auto;
    margin: auto;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    @include media-breakpoint-up(xl) {
      max-width: 1400px;
    }
    @include media-breakpoint-up(lg) {
      min-width: 500px;
      max-width: 1000px;
      padding: 40px 50px 0 50px;
    }
    @include media-breakpoint-down(md) {
      min-width: 0;
      max-width: calc(100vw - 40px);
      padding: 24px 30px 0 30px;
    }
    @include media-breakpoint-down(sm) {
      width: calc(100vw - 40px);
      padding: 20px 20px 0 20px;
    }

    &__close {
      position: absolute;
      top: 40px;
      right: 50px;
      @include media-breakpoint-down(md) {
        top: 24px;
        right: 30px;
      }
      @include media-breakpoint-down(sm) {
        top: 20px;
        right: 20px;
      }

      &[disabled] {
        opacity: 0.4;
      }

      svg {
        width: 18px;
        height: 18px;
        fill: $grey-mid;
        @include transitionSimple(fill);
      }
      &:hover svg {
        fill: $purple;
      }
    }

    &__title {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      padding-right: 50px;
      color: $purple;
    }

    &__body {
      padding: 30px 0 40px 0;
      &--has-footer {
        padding-bottom: 30px;
      }
    }

    &__footer {
      padding: 10px 0 40px 0;
      @include media-breakpoint-down(md) {
        padding-bottom: 24px;
      }
      @include media-breakpoint-down(sm) {
        padding-bottom: 10px;
      }

      .button {
        margin-left: 10px;
        min-width: 160px;
        text-align: center;
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-left: 0;
          margin-bottom: 10px;
          text-align: center;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &--open {
    visibility: visible;
    opacity: 1;
    transition: opacity .3s, visibility .3s;

    .modal-shroud,
    .modal-content {
      //opacity: 1;
    }
  }
}