// Typography variables
$font-family: 'Poppins', sans-serif;
$font-family-base: $font-family;
$font-family-heading: $font-family;
$font-size: 15px;
$line-height: 24px;
// Font size
$h1-font-size: 40px;
$h2-font-size: 25px;
$h3-font-size: 20px;
$h4-font-size: 18px;
$h5-font-size: 15px;
$h6-font-size: 15px;

$headings-font-weight: 700;
