.global-message {
  @include box();
  margin-bottom: $space;
  position: relative;

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;

    svg {
      width: 20px;
      height: 20px;
      fill: $grey-mid;
      @include transitionSimple(fill);
    }

    &:hover {
      svg {
        fill: $blue;
      }
    }
  }
}