.form--filter {
  width: 100%;
  background: none;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: $space;
  flex-wrap: wrap;
  .form__field {
    margin-bottom: 0;
    margin-right: $space;
    @include media-breakpoint-down(md) {
      min-width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
    &-label {
      white-space: nowrap;
      margin-right: 16px;
      margin-bottom: 0;
    }
    .form__field-error-message {
      display: none;
    }
  }
  .form__filter-buttons {
    flex-grow: 2;
    padding: 4px 0;
    text-align: right;
  }
  .form__submit-wrapper {
    padding-top: 0;
    margin-right: $space;
  }
}