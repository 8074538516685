html {
  @include full-height();
}
.body {
  background: $grey-body;
  min-width: 320px;
  @include full-height();
  &--menu-open {
    overflow: hidden;
  }
  &--admin-bar {
    padding-bottom: 70px;
  }
}