.form--login {
  width: 100%;
  .form__field-checkbox {
    input[type=checkbox]:not(:checked) + label, input[type=checkbox]:checked + label {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .link {
    font-weight: 500;
  }
  .form-row {
    font-size: 12px;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      .form__fields--remember {
        width: 100%;
        padding-top: $space;
        order: 2;
      }
    }
  }
}