@import '../../config/config';

.form__field-control--date:hover {
  cursor: pointer;
}

.react-datepicker {
  font-family: $font-family;
  border-color: $grey-mid;
  &__header {
    background: $grey-light;
    border-color: $grey-mid;
  }
  &__input-container {
    position: relative;
    min-width: 150px;
    @include media-breakpoint-up(lg) {
      max-width: 150px;
    }
  }
  &__current-month {
    font-weight: 500;
  }
  &__navigation-icon::before {
    border-color: $grey-dark;
    border-width: 2px 2px 0 0;
    top: 8px;
  }
  &__day {
    font-size: 13px;
    &-name {
      color: $grey-dark;
    }
  }
  &__day--keyboard-selected {
    &, &:hover {
      background-color: $grey-light;
      color: $black;
    }
  }
  &__day--selected {
    background-color: #00B3CF;
  }
}

.form__field-control--date {
  background-image: url('../../../images/icon/arrow-down.svg');
  background-repeat: no-repeat;
  background-size: 12px 8px;
  background-position: right 20px top 20px;
  &-open {
    background-image: url('../../../images/icon/arrow-up.svg');
  }
}

.react-datepicker__year-read-view--down-arrow {
  transform: none;
  border: none;
  top: 6px;
  background-image: url('../../../images/icon/arrow-down.svg');
  background-repeat: no-repeat;
  background-size: 12px 8px;
  background-position: center;
}

.react-datepicker__year-option {
  .react-datepicker__navigation {
    background-image: url('../../../images/icon/arrow-down.svg');
    background-repeat: no-repeat;
    background-size: 12px 8px;
    background-position: center;
  }
  &:first-child .react-datepicker__navigation {
    background-image: url('../../../images/icon/arrow-up.svg');
  }
}
