$buttonColours: (
    purple: $purple,
    blue: $blue
);
a.button {
  display: inline-block;
}
.button {
  white-space: nowrap;
  position: relative;
  &__inner {
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    position: relative;
    &:after {
      content: '';
      width: 0;
      position: absolute;
      bottom: -2px;
      left: 50%;
      height: 2px;
      transition: width .1s ease-in-out, left .1s ease-in-out;
    }
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: #FFF;
  }
  &__icon {
    margin-right: 10px;
    svg {
      margin-right: 0;
    }
  }
  &:hover .button__inner:after {
    left: 0;
    width: 100%;
  }
  &:active, &:focus {
    box-shadow: 0 0 0 2px rgba($blue,0.3);
  }
  &--purple {
    &:active, &:focus {
      box-shadow: 0 0 0 2px rgba($purple, 0.3);
    }
  }
  &--no-padding {
    padding: 0;
  }

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &__loading {
    margin-right: 8px;
    svg { margin-right: 0; }
  }
}

@import 'button--outline';
@import 'button--simple';
@import 'button--full';
@import 'button--modifiers'; // Needs to be last