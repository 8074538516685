.form__field-category-wrapper {
  position: relative;
  min-width: 150px;
  .form__field-control--select {
    height: 50px;
    background-color: #FFF;
    position: relative;
    padding-right: 40px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: $grey-dark;
    &[disabled] {
      border-color: $grey-mid;
      background-color: $grey-light;
      cursor: not-allowed;
    }
    &:after {
      content: '';
      position: absolute;
      right: 20px;
      top: 20px;
      width: 12px;
      height: 8px;
      background-image: url('../../../images/icon/arrow-down.svg');
      background-repeat: no-repeat;
      background-size: 12px 8px;
      @include transitionSimple(transform, .1s);
    }
    &-open {
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  .form__field-category-options {
    position: absolute;
    top: 58px;
    background: #FFF;
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
    z-index: 2;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    @include transitionSimple(max-height, .05s);
    > div {
      max-height: 40vh;
      padding: 0 0 56px 0;
      position: relative;
    }
    ul {
      max-height: calc(40vh - 56px);
      overflow-y: auto;
      list-style: none;
      padding: 10px 0;
      margin: 0;
    }
    &--open {
      max-height: 40vh;
      @include media-breakpoint-down(md) {
        max-height: 70vh;
      }
    }
    &-list-item {
      padding: 0;
      margin: 0;
      button {
        width: 100%;
        color: $black;
        padding: 10px 60px 10px 20px;
        font-weight: 600;
        position: relative;
        &:hover {
          color: $purple;
          background: lighten($grey-light, 3%);
        }
        &:after {
          position: absolute;
          content: '';
          border: 1px solid $blue;
          border-radius: 3px;
          width: 20px;
          height: 20px;
          right: 20px;
          top: 12px;
        }
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        .form__field-category-options-list-item button {
          font-weight: normal;
          padding: 10px 60px 10px 30px;

        }
      }
      &--selected > button:after {
        background-image: url('../../../images/icon/category-selected.svg');
        background-repeat: no-repeat;
        background-size: 12px 12px;
        background-position: center;
      }
    }
    &-footer {
      position: absolute;
      z-index: 10;
      background: #FFF;
      bottom: 0;
      padding: 10px;
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: space-between;
      .button {
        width: 100%;
        text-align: center;
      }
      .button:first-child {
        margin-right: 5px;
      }
      .button:last-child {
        margin-left: 5px;
      }
    }
  }
}